











import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { BlogFilter } from 'client-website-ts-library/filters';
import { BlogType } from 'client-website-ts-library/types';

import VideoBlogs from '../components/VideoBlogs.vue';

@Component({
  components: {
    VideoBlogs,
  },
})
export default class BuyersTips extends Mixins(View) {
  private filter = new BlogFilter({
    Tag: 'Buy',
  });
}
